<template>
    <div class="block-404">
        <router-link to="/" class="radio-block__button">Обратно на гравную</router-link>
        <img class="img-404" src="https://us.123rf.com/450wm/tuulijumala/tuulijumala1407/tuulijumala140700003/29672337-plantilla-vector-de-error-de-p%C3%A1gina-no-encontrada-con-fondo-blanco.jpg?ver=6" alt="404" srcset="">
        <router-link to="/" class="radio-block__button">Обратно на гравную</router-link>
    </div>
</template>

<script>
    
    export default{
        props: ['siteName'],
        data(){
            return{
                title: this.siteName
            }
        },
        created(){
            document.title = `Страница не найдена`
        }
    }
</script>

<style lang="scss">
    .block-404{
        display: flex;
        flex-direction: column;
    }

    .img-404{
        margin: auto;
    }
</style>